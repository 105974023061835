
button {
    font-family: 'Inter', sans-serif;
    padding: 0.5em 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--cyan-blue);
    color: var(--white);
    transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
    background-color: var(--light-orange);
    color: var(--white);
}

button:active {
    background-color: var(--obsidian-black);
    color: var(--white);
}

button:disabled {
    background-color: var(--off-white);
    color: var(--obsidian-black);
    cursor: not-allowed;
}

.primary-button {
    background-color: var(--cyan-blue);
    color: var(--white);
}

.secondary-button {
    background-color: var(--light-orange);
    color: var(--obsidian-black);
}
