@import './colors.css';
@import './buttons.css';

.client-container {
  padding: 20px;
  background-color: var(--off-white);
}

.client-section {
  background-color: var(--light-orange);
  border-radius: 8px;
  color: var(--white);
  padding: 20px;
  margin-bottom: 20px;
}

.client-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.client-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  line-height: 1.5;
  flex-wrap: wrap;
}

.client-actions {
  display: flex;
  gap: 10px;
}

.client-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .client-list li {
      flex-direction: column;
      align-items: flex-start;
  }

  .client-actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
  }

  .client-form {
      gap: 5px;
  }

  .client-form button {
      width: 100%;
  }
}

@media (max-width: 480px) {
  .client-list li {
      font-size: 14px;
  }

  .client-actions button,
  .client-form button {
      width: 100%;
  }
}
