.login-page {
    display: flex;
    height: 100vh;
}

.left-half, .right-half {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-half {
    background-color: var(--light-orange);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo-container img {
    max-width: 80%; 
    max-height: 80%;
}

.right-half {
    background-color: var(--off-white);
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.right-half h1 {
    color: var(--obsidian-black);
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
}

button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--cyan-blue);
    color: var(--white);
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: var(--light-orange);
}

@media (max-width: 768px) {
    .login-page {
        flex-direction: column;
    }

    .left-half, .right-half {
        width: 100%;
        height: 50vh;
    }

    .logo-container img {
        max-width: 60%; 
    }

    .right-half {
        padding: 10px;
    }

    .button-group {
        flex-direction: column;
        gap: 15px;
    }
}
