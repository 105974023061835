@import './colors.css';
@import './buttons.css';

.operator-container {
    padding: 20px;
    background-color: var(--off-white);
}

.operator-section {
    background-color: var(--light-orange);
    border-radius: 8px;
    color: var(--white);
    padding: 20px;
    margin-bottom: 20px;
}

.operator-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
}

.operator-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1.5;
    flex-wrap: wrap; 
}

.operator-actions {
    display: flex;
    gap: 10px;
}

.operator-form-section {
    background-color: var(--light-orange);
    border-radius: 8px;
    color: var(--white);
    padding: 20px;
}

.operator-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.operator-selection {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.operator-selection p {
    margin: 0;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .operator-list li {
        flex-direction: column;
        align-items: flex-start;
    }

    .operator-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .operator-form {
        gap: 5px;
    }

    .operator-selection {
        flex-direction: column;
        gap: 5px;
    }

    .operator-form button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .operator-list li {
        font-size: 14px;
    }

    .operator-actions button,
    .operator-form button {
        width: 100%;
    }

    .operator-selection button {
        width: 100%;
    }
}
